import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { AcknowledgeModal, ActionModal, FormField, Modal, Spacer, Text } from '@percent/lemonade'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useState } from 'react'
import { useFormik } from 'formik'
import { Kind } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'
import { useCountryData } from '@percent/partner-dashboard/common/hooks/useCountryData/useCountryData'
import { getCountryNamesFromAlpha3Codes } from '@percent/partner-dashboard/i18n/countries'
import { FeatureFundraiserModalProps } from './FeatureFundraiserModal.types'
import {
  formatCountryNamesString,
  mapCountriesToOptions
} from '@percent/partner-dashboard/common/library/utility/utility'
import { MultiSelect } from '@percent/partner-dashboard/common/components'

export function FeatureFundraiserModal({
  isOpen,
  onClose,
  refresh,
  fundraiser
}: Readonly<FeatureFundraiserModalProps>) {
  const { t } = useTranslation()
  const { workplaceGivingService } = useServices()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const { countries } = useCountryData()

  const [{ isLoading, data }, { apiFunc: featureFundraiserFunc }] = useMutation(
    workplaceGivingService.featureItem,
    () => {
      setIsSuccess(true)
    },
    () => {
      setIsError(true)
    }
  )

  const handleCloseSuccessModal = () => {
    setIsSuccess(false)
    refresh()
    onClose()
  }

  const handleCloseErrorModal = () => {
    setIsError(false)
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  const countryOptions = mapCountriesToOptions(countries)

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      country: []
    },
    validationSchema: Yup.object().shape({
      country: Yup.array().of(Yup.string())
    }),
    onSubmit: () => featureFundraiserFunc({ country: values.country, kind: Kind.Fundraiser, itemId: fundraiser.id })
  })

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="open-feature-fundraiser"
        data-testid="open-feature-fundraiser"
      >
        <ActionModal
          title={t('dialog.fundraisers.feature.title')}
          primaryButtonText={t('button.confirm')}
          secondaryButtonText={t('button.cancel')}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          loading={!!isLoading}
          primaryBtnTestId="btn-feature-fundraiser"
          disabled={!!isLoading}
        >
          <Text size="small">
            <Trans
              i18nKey="dialog.fundraisers.feature.description"
              values={{ fundraiserTitle: fundraiser.page.title }}
              components={{ strong: <strong /> }}
            />
          </Text>
          <Spacer size={4} axis="vertical" />
          <FormField
            label={t('dialog.fundraisers.feature.select.label')}
            necessity="optional"
            data-testid="country-select"
            description={t('dialog.fundraisers.feature.select.description')}
          >
            <MultiSelect
              value={values.country}
              setFieldValue={setFieldValue}
              options={countryOptions}
              onChangeFieldName="country"
              placeholder={t('dialog.fundraisers.feature.select.placeholder')}
            />
          </FormField>
        </ActionModal>
      </Modal>
      {data && (
        <Modal
          open={isSuccess}
          onClose={handleCloseSuccessModal}
          aria-labelledby="feature-fundraiser-success"
          data-testid="feature-fundraiser-success"
        >
          <AcknowledgeModal
            result="positive"
            title={t('dialog.fundraisers.feature.success.title')}
            description={
              data?.country.length > 0
                ? t('dialog.fundraisers.feature.success.countrySpecific', {
                    fundraiserTitle: fundraiser.page.title,
                    countries: formatCountryNamesString(getCountryNamesFromAlpha3Codes(data?.country))
                  })
                : t('dialog.fundraisers.feature.success.global', {
                    fundraiserTitle: fundraiser.page.title
                  })
            }
            buttonText={t('button.close')}
            handleClose={handleCloseSuccessModal}
            buttonTestId="success-modal-btn"
          />
        </Modal>
      )}
      <Modal
        open={isError}
        onClose={handleCloseErrorModal}
        aria-labelledby="feature-fundraiser-error"
        data-testid="feature-fundraiser-error"
      >
        <AcknowledgeModal
          result="negative"
          title={t('dialog.fundraisers.feature.error.title')}
          description={t('dialog.error.description')}
          buttonText={t('button.close')}
          handleClose={handleCloseErrorModal}
          buttonTestId="error-modal-btn"
        />
      </Modal>
    </>
  )
}
