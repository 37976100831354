import { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Modal, ActionModal, Text, Spacer, FormField, AcknowledgeModal } from '@percent/lemonade'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { CurrencyInput } from '@percent/domain/giving'
import { formatMoney } from '@percent/utility'
import { EmployeesModalProps } from './EmployeesModal.types'

export function AddWalletBalanceModal({ isOpen, onClose, employee, refresh }: EmployeesModalProps) {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()
  const { workplaceGivingService } = useServices()

  const [{ isLoading, data }, { apiFunc }] = useMutation(
    workplaceGivingService.addWalletBalance,
    () => {
      onClose()
      setIsSuccess(true)
      refresh()
    },
    () => {
      onClose()
      setIsError(true)
    }
  )

  const handleCloseSuccessModal = () => {
    setIsSuccess(false)
    resetForm()
  }

  const handleCloseErrorModal = () => {
    setIsError(false)
    resetForm()
  }

  const { errors, touched, dirty, values, handleSubmit, isValid, setFieldValue, resetForm } = useFormik({
    initialValues: {
      amount: 0
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required(t('errorMessage.required')).positive(t('errorMessage.postiveNumber'))
    }),
    onSubmit: async ({ amount }) => {
      await apiFunc({
        accountId: employee.id,
        amount: {
          amount,
          currency: employee.currency
        }
      })
    }
  })

  const handleClose = () => {
    resetForm()
    onClose()
  }

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ActionModal
          title={t('dialog.employees.wallet.title')}
          primaryButtonText={t('button.addToBalance')}
          secondaryButtonText={t('button.cancel')}
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && !isLoading && dirty)}
          loading={isLoading}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          primaryBtnTestId="reject-request"
          secondaryBtnTestId="cancel-request"
          aria-labelledby="add-wallet-balance-modal"
        >
          <Text size="small">
            {employee.fullName
              ? t('dialog.employees.wallet.subTitleWithPossessive', {
                  name: employee.fullName,
                  email: employee.email,
                  balance: employee?.wallet?.balance
                    ? formatMoney(employee?.wallet?.balance, {
                        locale,
                        hideDecimalsIfZero: true
                      })
                    : formatMoney(
                        { amount: 0, currency: employee.currency },
                        {
                          locale,
                          hideDecimalsIfZero: true
                        }
                      )
                })
              : t('dialog.employees.wallet.subTitleWithoutPossessive', {
                  email: employee.email,
                  balance: employee?.wallet?.balance
                    ? formatMoney(employee?.wallet?.balance, {
                        locale,
                        hideDecimalsIfZero: true
                      })
                    : formatMoney(
                        { amount: 0, currency: employee.currency },
                        {
                          locale,
                          hideDecimalsIfZero: true
                        }
                      )
                })}
          </Text>
          <form onSubmit={handleSubmit}>
            <Spacer size={4} axis="vertical" />
            <FormField
              label={t('dialog.employees.wallet.label')}
              necessity="required"
              status={errors.amount ? 'danger' : 'default'}
              statusMessage={errors.amount}
              description={
                values.amount
                  ? t('dialog.employees.wallet.description', {
                      balance: formatMoney(
                        {
                          amount: values.amount + (employee.wallet?.balance?.amount ?? 0),
                          currency: employee.currency
                        },
                        {
                          locale,
                          hideDecimalsIfZero: true
                        }
                      )
                    })
                  : ''
              }
              data-testid="amountInput"
            >
              <CurrencyInput
                id="amount"
                name="amount"
                status={touched.amount && errors.amount ? 'danger' : 'default'}
                value={values?.amount ? { amount: values.amount, currency: employee.currency } : undefined}
                onValueChange={value => setFieldValue('amount', value?.amount)}
                currency={employee.currency}
                locale={locale}
                allowNegativeValue={false}
                placeholder={{ amount: 1000, currency: employee.currency }}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
          </form>
        </ActionModal>
      </Modal>
      {data && (
        <Modal open={isSuccess} onClose={handleCloseSuccessModal} aria-labelledby="add-wallet-balance-success">
          <AcknowledgeModal
            result="positive"
            title={t('dialog.employees.wallet.success.title', {
              name: employee?.fullName || employee.email,
              balance: formatMoney(
                { amount: values.amount, currency: employee.currency },
                {
                  locale,
                  hideDecimalsIfZero: true
                }
              )
            })}
            description={t('dialog.employees.wallet.success.description', {
              balance: formatMoney(data.data.data.balance, {
                locale,
                hideDecimalsIfZero: true
              })
            })}
            buttonText={t('button.close')}
            handleClose={handleCloseSuccessModal}
            buttonTestId="success-modal-btn"
          />
        </Modal>
      )}

      <Modal open={isError} onClose={handleCloseErrorModal} aria-labelledby="add-wallet-balance-error">
        <AcknowledgeModal
          result="negative"
          title={t('dialog.employees.wallet.error.title')}
          description={t('dialog.error.description')}
          buttonText={t('button.close')}
          handleClose={handleCloseErrorModal}
          buttonTestId="error-modal-btn"
        />
      </Modal>
    </>
  )
}
