import { getAllCountryNames, loadCountryData } from '@percent/partner-dashboard/i18n/countries'
import { useEffect, useState } from 'react'

export const useCountryData = () => {
  const [countries, setCountries] = useState<{ alpha3Code: string; countryName: string }[]>([])

  useEffect(() => {
    loadCountryData()
    const countryNames = getAllCountryNames()
    setCountries(countryNames)
  }, [])

  return { countries }
}
