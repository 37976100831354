import styled from 'styled-components'
import {
  Badge,
  Heading,
  Text,
  Icon,
  ProgressBar,
  Tooltip,
} from '@percent/lemonade'
import { FeaturedCardProps } from './featured-card.types'
import { Skeleton } from '@mui/material'
import { formatMoney } from '@percent/utility'
import { useRef, useEffect, useState } from 'react'

const FeaturedCardContent = styled.div`
  ${({ theme }) =>
    `
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      width: 100%;

      @media only screen and (max-width: 767px) {
        width: 100%;
        border-radius: ${theme.sizes[2]};
      }
    `}

  h5 {
    font-weight: 600;
    text-align: left;
    margin-bottom: -8px;
    line-height: 32px;
    max-width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const FeaturedCardImage = styled.div<{ imgSrc: string }>`
  ${({ imgSrc, theme }) =>
    `
      width: 100%;
      height: 278px;
      margin-bottom: 24px;
      background-image: url(${imgSrc});;
      background-size: cover;
      background-position: center;
      border-radius: ${theme.sizes[4]};
      position: relative;
      border: 1px solid ${theme.colors.gray90};
    `}
`
const OverlayImage = styled.div<{ imgSrc: string }>`
  ${({ theme, imgSrc }) =>
    `
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
    background-image: url(${imgSrc});
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: ${theme.sizes[2]};
    border: 1px solid ${theme.colors.gray90};
  `}
`

const FeaturedDateDetails = styled.div`
  ${({ theme }) =>
    `
    & > div {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: ${theme.sizes[2]};
    }

    & p {
      color: ${theme.colors.gray600};
    }
`}
`

const FeaturedCardButtonInfo = styled.div`
  display: flex;
  align-items: center;

  button {
    ${({ theme }) => `
      margin-right: ${theme.sizes.xs};
    `}
  }
`

const StyledAmount = styled.p`
  margin: 0px;
  margin-top: -15px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray600}
}
`

const FeaturedCardWrapper = styled.div`
  cursor: pointer;
`

export const FeaturedCardSkeleton = () => (
  <div>
    <FeaturedCardContent>
      <Skeleton
        variant="rectangular"
        width={300}
        height={40}
        style={{ borderRadius: 4 }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={288}
        style={{ borderRadius: 4 }}
      />
      <Skeleton variant="rectangular" width={100} style={{ borderRadius: 4 }} />
      <Skeleton variant="rectangular" width="100%" height={66} />
      <Skeleton variant="rectangular" width={100} style={{ borderRadius: 4 }} />
    </FeaturedCardContent>
  </div>
)

export const FeaturedCard = (props: Readonly<FeaturedCardProps>) => {
  const {
    type,
    title,
    badgeText,
    organisationName,
    organisationLogo,
    actionButton,
    imgSrc,
    cardButtonInfo,
  } = props

  const titleRef = useRef<HTMLHeadingElement | null>(null)
  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false)

  useEffect(() => {
    if (titleRef.current) {
      const isOverflowing =
        titleRef.current.scrollWidth > titleRef.current.clientWidth
      setIsTitleOverflowing(isOverflowing)
    }
  }, [title])

  return (
    <FeaturedCardWrapper
      data-testid="featured-card-wrapper"
      onClick={actionButton}
    >
      {imgSrc && (
        <FeaturedCardImage imgSrc={imgSrc} data-testid="featured-image">
          {organisationLogo && (
            <Tooltip content={organisationName} placement="top">
              <OverlayImage
                imgSrc={organisationLogo}
                data-testid="organisation-logo"
              />
            </Tooltip>
          )}
        </FeaturedCardImage>
      )}
      <FeaturedCardContent>
        <Badge variant="informative">
          <Text as="p" size="small">
            {badgeText}
          </Text>
        </Badge>

        {isTitleOverflowing ? (
          <Tooltip content={title} placement="top">
            <Heading ref={titleRef} data-testid="featured-title" level="h5">
              {title}
            </Heading>
          </Tooltip>
        ) : (
          <Heading ref={titleRef} data-testid="featured-title" level="h5">
            {title}
          </Heading>
        )}

        {type === 'opportunity' ? (
          <FeaturedDateDetails>
            {!props.startDate ? null : (
              <div data-testid="date-container">
                <Icon name="calendar" size="m" color="neutral300" />
                <Text
                  as="p"
                  size="small"
                  fontWeight="medium"
                  dataTestId="start-date"
                >
                  {props.startDate}
                </Text>
              </div>
            )}
            <div>
              <Icon
                name={
                  props.locationType === 'virtual' ? 'virtual-meeting' : 'pin'
                }
                size="m"
                color="neutral300"
              />
              <Text as="p" size="small" fontWeight="medium">
                {props?.locationText}
              </Text>
            </div>
          </FeaturedDateDetails>
        ) : (
          <>
            <ProgressBar
              progressPercentage={Math.min(
                (props.total.amount / props.goal.amount) * 100,
                100
              )}
              backgroundColorComplete="info400"
              backgroundColorIncomplete="info400"
              shadeColor="gray90"
              sizeVariant="x-small"
              enableAnimation
            />
            <StyledAmount>
              {formatMoney(props.total, {
                hideDecimalsIfZero: true,
                locale: props.locale,
              })}{' '}
              of{' '}
              {formatMoney(props.goal, {
                hideDecimalsIfZero: true,
                locale: props.locale,
              })}
            </StyledAmount>
          </>
        )}
        <FeaturedCardButtonInfo>{cardButtonInfo}</FeaturedCardButtonInfo>
      </FeaturedCardContent>
    </FeaturedCardWrapper>
  )
}
