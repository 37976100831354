import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  ProgressBarProps,
  sizeMap,
  StyledProgressBarProps,
  StyledProgressBarShadeProps,
  StyledProgressBarWrapperProps,
} from './progress-bar.types'

const ProgressBarWrapper = styled.div<StyledProgressBarWrapperProps>`
  ${({ $size }) => css`
    position: relative;
    width: 100%;
    height: ${sizeMap[$size]};
    margin: 8px 0;
    background-color: ${({ theme }) => theme.gray70};
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
  `}
`

const CommonProgressBar = styled.div<{ enableAnimation: boolean }>`
  background-color: ${({ theme }) => theme.info400};
  max-width: 100% !important;
  ${({ enableAnimation }) =>
    enableAnimation
      ? css`
          transition: all 0.5s ease-in-out;
        `
      : css`
          transition: none;
        `}
`

const StyledProgressBar = styled(CommonProgressBar)<StyledProgressBarProps>`
  ${({
    theme,
    $isProgressBarFull,
    progressPercentage,
    $backgroundColorComplete,
    $backgroundColorIncomplete,
  }) => css`
    width: ${progressPercentage}%;
    background-color: ${$isProgressBarFull
      ? theme.colors[$backgroundColorComplete]
      : theme.colors[$backgroundColorIncomplete]};
  `}
`

const StyledProgressBarShade = styled(
  CommonProgressBar
)<StyledProgressBarShadeProps>`
  ${({
    theme,
    $isProgressBarFull,
    $backgroundColorComplete,
    $backgroundColorIncomplete,
    $hasOpacity,
    enableAnimation,
  }) => css`
    flex: 1;
    background-color: ${$isProgressBarFull
      ? theme.colors[$backgroundColorComplete]
      : theme.colors[$backgroundColorIncomplete]};
    opacity: ${$hasOpacity ? 0.4 : 1};
    ${() =>
      enableAnimation
        ? css`
            transition-delay: 0s;
          `
        : css`
            transition: none;
          `}
  `}
`

export const ProgressBar = ({
  progressPercentage,
  backgroundColorComplete,
  backgroundColorIncomplete,
  shadeColor,
  sizeVariant = 'medium',
  enableAnimation = false,
}: ProgressBarProps) => {
  const [animatedProgress, setAnimatedProgress] = useState<number[]>(
    Array.isArray(progressPercentage) ? progressPercentage.map(() => 0) : [0]
  )

  const timeoutsRef = useRef<ReturnType<typeof setTimeout>[]>([])

  useEffect(() => {
    if (!enableAnimation) {
      timeoutsRef.current.forEach((timeout) => clearTimeout(timeout))
      timeoutsRef.current = []
      setAnimatedProgress(
        Array.isArray(progressPercentage)
          ? progressPercentage
          : [progressPercentage]
      )
      return
    }

    if (Array.isArray(progressPercentage)) {
      progressPercentage.forEach((value, index) => {
        const timeout = setTimeout(() => {
          setAnimatedProgress((prev) =>
            progressPercentage.map((_, idx) =>
              idx === index ? Math.max(prev[idx] ?? 0, value) : prev[idx]
            )
          )
        }, 200)

        timeoutsRef.current.push(timeout)
      })
    } else {
      const timeout = setTimeout(() => {
        setAnimatedProgress((prev) => [
          Math.max(prev[0] ?? 0, progressPercentage),
        ])
      }, 200)

      timeoutsRef.current.push(timeout)
    }

    return () => {
      timeoutsRef.current.forEach((timeout) => clearTimeout(timeout))
      timeoutsRef.current = []
    }
  }, [progressPercentage, enableAnimation])

  const progressPercentageArray = Array.isArray(animatedProgress)
    ? animatedProgress
    : [animatedProgress]

  const backgroundColorCompleteArray = Array.isArray(backgroundColorComplete)
    ? backgroundColorComplete
    : [backgroundColorComplete]

  const backgroundColorIncompleteArray = Array.isArray(
    backgroundColorIncomplete
  )
    ? backgroundColorIncomplete
    : [backgroundColorIncomplete]

  if (
    progressPercentageArray.length !== backgroundColorCompleteArray.length ||
    progressPercentageArray.length !== backgroundColorIncompleteArray.length
  ) {
    console.error('[ProgressBar] Please provide colors for all values')
    return null
  }

  const isProgressBarFull =
    progressPercentageArray.reduce((sum, value) => sum + value, 0) >= 100
  const lastBarIndex = progressPercentageArray.length - 1

  return (
    <ProgressBarWrapper $size={sizeVariant} data-testid="progress-bar-wrapper">
      {progressPercentageArray.map((value, index) => (
        <StyledProgressBar
          key={`bar-${index}`}
          progressPercentage={value}
          $isProgressBarFull={isProgressBarFull}
          $backgroundColorComplete={backgroundColorCompleteArray[index]}
          $backgroundColorIncomplete={backgroundColorIncompleteArray[index]}
          enableAnimation={enableAnimation}
        />
      ))}

      <StyledProgressBarShade
        $isProgressBarFull={isProgressBarFull}
        $backgroundColorComplete={
          shadeColor || backgroundColorCompleteArray[lastBarIndex]
        }
        $backgroundColorIncomplete={
          shadeColor || backgroundColorIncompleteArray[lastBarIndex]
        }
        $hasOpacity={!shadeColor}
        enableAnimation={enableAnimation}
      />
    </ProgressBarWrapper>
  )
}
