import { Table as MantineTable } from '@mantine/core'
import { ReactNode } from 'react'
import { ColumnProps } from '../table.types'

export function TableBody({ children }: { children: ReactNode }) {
  return <MantineTable.Tbody>{children}</MantineTable.Tbody>
}

export function TableRow({ children }: { children: ReactNode }) {
  return <MantineTable.Tr>{children}</MantineTable.Tr>
}

export function TableCell({
  children,
  inlineStyle,
  className,
  dataTestId,
}: {
  children: ReactNode
  inlineStyle?: ColumnProps
  className?: string
  dataTestId?: string
}) {
  return (
    <MantineTable.Td
      style={inlineStyle}
      className={className}
      data-testId={dataTestId}
    >
      {children}
    </MantineTable.Td>
  )
}
