import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { FundraisersTableProps } from '../FundraisersTable/FundraisersTable.types'
import { FundraiserStatus } from '@percent/partner-dashboard/services/fundraisers/fundraisersService.types'
import { useHistory } from 'react-router-dom'

const statusMapping: Record<FundraiserStatus, string> = {
  [FundraiserStatus.Published]: 'Open',
  [FundraiserStatus.Closed]: 'Closed'
}

export const useStatusFilter = ({
  queryParams,
  setQueryParams
}: Pick<FundraisersTableProps, 'queryParams' | 'setQueryParams'>) => {
  const { t } = useTranslation()
  const history = useHistory()

  const statusOptions = useMemo(
    () => [
      { label: t('status.all'), value: '' },
      { label: t('status.closed'), value: FundraiserStatus.Closed },
      { label: t('status.open'), value: FundraiserStatus.Published }
    ],
    [t]
  )

  const onStatusChange = useCallback(
    (status: string) => {
      setQueryParams({
        status
      })

      if (!status) {
        const { pathname, search } = history.location
        const searchParams = new URLSearchParams(search)
        searchParams.delete('status')
        history.replace(`${pathname}?${searchParams.toString()}`)
      }
    },
    [setQueryParams, history]
  )

  return {
    statusOptions,
    onStatusChange,
    selectedStatus: statusMapping[queryParams?.status] || ''
  }
}
