import { CurrencyCode, Money } from '@percent/utility'
import { AxiosInstance } from 'axios'
import { Organisation } from '../nonprofits/nonprofitsService.types'

export type WorkplaceGivingServiceCapabilities = {
  percentClient: AxiosInstance
}

export type WorkplaceParamsProps = {
  page?: number | null
  pageSize?: number | null
}

export type GetEmployeesListProps = WorkplaceParamsProps & {
  filter?: string | null
}

type MatchingBudget = Record<'remaining' | 'total' | 'used', Money>

export type Employee = {
  email: string
  fullName: string | null
  id: string
  matching: MatchingBudget | null
  team: string[]
  wallet: {
    balance: Money
  } | null
  currency: CurrencyCode | null
  country: string | null
}

export type UpdatedEmployee = Omit<Employee, 'currency'> & {
  currency: CurrencyCode
}

export type AddWalletBalanceProps = {
  accountId: string
  amount: Money
}

export type AssignAchievementProps = {
  accountId: string
  achievementId: string
}

export type UpdateMatchingBudgetProps = Pick<AddWalletBalanceProps, 'accountId'> & { total: Money }

export type DonationWallet = {
  id: string
  partnerId: string
  accountId: string
  balance: Money
}

export type ConditionStateAndConditionAccountLimit = Omit<DonationWallet, 'balance'> & {
  createdAt: string
  conditionId: string
  state: MatchingBudget
  change: {
    id: string
    change: string
    createdAt: string
    createdBy: string
    oldState: MatchingBudget
    newState: MatchingBudget
    kind: 'account_limit'
  }
}

export type GetVolunteeringOpportunitiesListProps = {
  page?: number | null
  pageSize?: number | null
  query?: string | null
  parentId?: string | null
  status?: (string | null)[] | null
}

export enum OpportunityStatus {
  ACTIVE = 'published',
  CLOSED = 'closed',
  CANCELLED = 'cancelled'
}

export enum OpportunityType {
  PROJECT = 'project',
  EVENT = 'event',
  EVENT_SERIES = 'event_series',
  EVENT_SERIES_SESSION = 'event_series_session'
}

export enum OpportunityLocationType {
  VIRTUAL = 'virtual',
  OFFLINE = 'in-person'
}

export type OnlineLocation = {
  type: OpportunityLocationType.VIRTUAL
  link?: string
}

export type OfflineLocation = {
  type: OpportunityLocationType.OFFLINE
  country: string
  city: string
  zipCode: string
  addressLineOne: string
  addressLineTwo?: string
  locationName?: string
  locationDisplayName?: string
  long: number
  lat: number
}

export type OpportunityLocationDetails = OnlineLocation | OfflineLocation

export type OpportunityTimeTracking = 'automatic' | 'manual'

export type VolunteeringOpportunity = {
  id: string
  name: string
  description: string | null
  image: string | null
  skills: {
    id: string
    name: string
  }[]
  itinerary:
    | {
        id: number
        durationMinutes: number
        title: number
        description?: string
      }[]
    | null
  location: OpportunityLocationDetails
  startDate: string | null
  endDate: string | null
  parentId: string | null
  activities: {
    id: string
    name: string
  }[]
  external: {
    provider: string
  } | null
  organisation: (Pick<Organisation, 'id'> & Partial<Pick<Organisation, 'name' | 'logo' | 'countryCode'>>) | null
  participants: {
    spots: number | null
    count: number
  }
  timeTracking: OpportunityTimeTracking

  createdBy: {
    id: string
    email: string | null
    fullName: string | null
  } | null
  type: OpportunityType
  status: OpportunityStatus
  createdAt: string
}

export type VolunteeringOpportunityDetails = Omit<VolunteeringOpportunity, 'createdBy'> & {
  organiser: {
    id: string
    email: string | null
    fullName: string | null
  } | null
}

export type CancelOpportunityErrorCode =
  | 'opportunity/cannot_be_cancelled'
  | 'opportunity/cancel_invalid_state'
  | 'opportunity/cannot_have_published_children'
  | 'opportunity/external'

export type Achievement = {
  id: string
  achievementId: string
  name: string
  image: string
}

export enum Kind {
  Fundraiser = 'fundraiser',
  Opportunity = 'opportunity'
}

export type FeatureItemProps = {
  country: string[]
  kind: Kind
  itemId: string
}

export type FeatureItemResponse = {
  id: string
  createdAt: string
  updatedAt: string
  createdBy: string
  partnerId: string
  kind: Kind
  itemId: string
  country: string[]
}
