import { AxiosInstance } from 'axios'

import { Money } from '@percent/utility'

export type FundraisersServiceCapabilities = {
  percentClient: AxiosInstance
}

export enum FundraiserStatus {
  Published = 'published',
  Closed = 'closed'
}

export type GetFundraisersListProps = {
  page?: number | null
  pageSize?: number | null
  status?: FundraiserStatus | string | null
  query?: string | null
}

export type Fundraiser = {
  id: string
  status: FundraiserStatus
  organisation: {
    name: string
  }
  organiser: {
    kind: string
    id: string
    name: string
    email: string
  }
  campaign: {
    goal: Money
  }
  page: {
    title: string
  }
  donationTotal: Money
  createdAt: string
  featured?: boolean
}
