import { Fundraiser } from '@percent/partner-dashboard/services/fundraisers/fundraisersService.types'
import { useState } from 'react'

export function useFeatureFundraiserActionModal() {
  const [selectedFeaturedFundraiser, setSelectedFeaturedFundraiser] = useState<Fundraiser | null>(null)
  const [isFeaturedFundraiserModalOpen, setIsFeaturedFundraiserModalOpen] = useState<boolean>(false)

  const openFeaturedFundraiserModal = (fundraiser: Fundraiser) => {
    setSelectedFeaturedFundraiser(fundraiser)
    setIsFeaturedFundraiserModalOpen(true)
  }

  const closeFeaturedFundraiserModal = () => {
    setIsFeaturedFundraiserModalOpen(false)
  }

  return {
    selectedFeaturedFundraiser,
    isFeaturedFundraiserModalOpen,
    openFeaturedFundraiserModal,
    closeFeaturedFundraiserModal
  }
}
