import { Color } from '../../theme'

export const sizeMap = {
  'x-small': '4px',
  small: '8px',
  medium: '12px',
}

type SizeVariant = keyof typeof sizeMap

export type ProgressBarProps = {
  progressPercentage: number | number[]
  backgroundColorComplete: Color | Color[]
  backgroundColorIncomplete: Color | Color[]
  shadeColor?: Color
  sizeVariant?: 'x-small' | 'small' | 'medium'
  enableAnimation?: boolean
}

export type StyledProgressBarWrapperProps = {
  $size: SizeVariant
}

export type StyledProgressBarShadeProps = {
  $isProgressBarFull: boolean
  $backgroundColorComplete: Color
  $backgroundColorIncomplete: Color
  $hasOpacity: boolean
  enableAnimation: boolean
}

export type StyledProgressBarProps = Omit<
  StyledProgressBarShadeProps,
  '$hasOpacity'
> & {
  progressPercentage: number
}
