import countries from 'i18n-iso-countries'
import * as Sentry from '@sentry/react'
import enLocale from 'i18n-iso-countries/langs/en.json'

let isCountryDataLoaded = false

export const loadCountryData = () => {
  try {
    if (!isCountryDataLoaded) {
      if (!Object.keys(countries.getNames('en') || {}).length) {
        countries.registerLocale(enLocale)
      }
      isCountryDataLoaded = true
    }
  } catch (error) {
    console.warn('Error while registering i18n country locale:', error)
    Sentry.captureException(error)
  }
}

export const getAllCountryNames = () => {
  const countryNames = countries.getNames('en')
  return Object.entries(countryNames).map(([alpha2Code, countryName]) => ({
    alpha3Code: countries.alpha2ToAlpha3(alpha2Code),
    countryName
  }))
}

export const getCountryNamesFromAlpha3Codes = alpha3Codes => {
  loadCountryData()
  return alpha3Codes.map(alpha3Code => countries.getName(alpha3Code, 'en'))
}
